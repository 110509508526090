<template>
  <!-- Breadcroumbs start -->
  <Breadcrumbs :title="title" />
  <!-- Breadcroumbs end -->
  <!-- Login start -->
  <div class="bee-content-block">
    <div class="container">
      <div class="customer-login">
        <div v-if="loggedIn" class="row">
          <div class="col-12 col-lg-6">
            <div class="customer-login-left">
              <div class="login-icon"><i class="fa fa-lock"></i></div>
              <h4>Welcome to your account</h4>
              <p>
                Login your account and buy what you need.<br />
                If you have no account, please
                <a @click="changeForm()" href="#">Create a new account</a>.
              </p>
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div class="customer-login-block">
              <h3>Login to your account</h3>
              <form action="#">
                <div class="form-group">
                  <label>Email address:</label>
                  <input v-model="email" type="email" class="form-control" />
                </div>
                <div class="form-group">
                  <label>Password:</label>
                  <input
                    v-model="password"
                    type="password"
                    class="form-control"
                  />
                </div>
                <div class="checkbox">
                  <input
                    type="checkbox"
                    name="remember"
                    id="remember"
                    class="css-checkbox"
                  />
                  <label for="remember" class="css-label">Remember me</label>
                  <a href="#" title="" class="forgot-pass"
                    >Forgot your password?</a
                  >
                </div>
                <button
                  type="button"
                  class="btn btn-submit"
                  @click.prevent="login()"
                >
                  Login
                </button>
              </form>
            </div>
          </div>
        </div>
        <div v-else class="row">
          <div class="col-12 col-lg-6">
            <div class="customer-login-left">
              <div class="login-icon"><i class="fa fa-unlock-alt"></i></div>
              <h4>Welcome To new account</h4>
              <p>
                Create your account and buy what you need.<br />
                If you have account already, please
                <a @click="changeForm()">Sign in</a>.
              </p>
              <div class="">
                <a href="" class="btn btn-social btn-facebook"
                  ><i class="fa fa-facebook"></i> Sign in with Facebook</a
                >
                <a href="" class="btn btn-social btn-google-plus"
                  ><i class="fa fa-google-plus"></i> Sign in with Google</a
                >
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div class="customer-login-block">
              <h3>Registration</h3>
              <form action="#">
                <div class="form-group">
                  <div class="row">
                    <div class="col-12 col-sm-6">
                      <label>First Name:</label>
                      <input
                        v-model="firstName"
                        type="text"
                        class="form-control"
                        name="fname"
                        required
                      />
                    </div>
                    <div class="col-12 col-sm-6">
                      <label>Last Name:</label>
                      <input
                        v-model="lastName"
                        name="lastName"
                        type="text"
                        class="form-control"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="row">
                    <div class="col-12 col-sm-6">
                      <label>Email:</label>
                      <input
                        v-model="email"
                        type="email"
                        class="form-control"
                        name="email"
                        required
                      />
                    </div>
                    <!-- <div class="col-12 col-sm-6">
                      <label>Phone:</label>
                      <input type="text" class="form-control" name="phone" required />
                    </div> -->
                  </div>
                </div>
                <div class="form-group">
                  <div class="row">
                    <div class="col-12 col-sm-6">
                      <label>Password:</label>
                      <input
                        v-model="password"
                        type="password"
                        class="form-control"
                        name="pass"
                        required
                      />
                    </div>
                    <div class="col-12 col-sm-6">
                      <label>Confirm Password:</label>
                      <input
                        type="password"
                        class="form-control"
                        name="cpass"
                        required
                      />
                    </div>
                  </div>
                </div>
                <button
                  type="submit"
                  class="btn btn-submit"
                  @click.prevent="register"
                >
                  Registration
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Login End -->

  <Newsletter />
</template>

<script>
// @ is an alias to /src

import Breadcrumbs from "@/components/Breadcrumbs.vue";
import Newsletter from "@/components/Newsletter.vue";
import { auth } from "@/includes/firebase";

export default {
  name: "Login",
  data() {
    return {
      loggedIn: true,
      title: "Login",
      firstName: null,
      lastName: null,
      email: null,
      password: null,
    };
  },
  methods: {
    changeForm() {
      this.loggedIn = !this.loggedIn;
      if (this.title === "Login") {
        this.title = "Register";
      } else {
        this.title = "Login";
      }
    },
    async login() {
      try {
        await this.$store.dispatch("login", {
          password: this.password,
          email: this.email,
        });
        this.$router.replace("admin");
      } catch (error) {
        console.log(error);
      }
    },

    async signin() {
      await auth
        .signInWithEmailAndPassword(this.email, this.password)
        .then(() => {})
        .catch(() => {
          // const errorCode = error.code;
          // const errorMessage = error.message;
          // // ..
        });
    },
    async register() {
      try {
        await this.$store.dispatch("register", {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          password: this.password,
        });
        this.$router.replace("admin");
      } catch (error) {
        console.log("test: ", error);
      }
    },

    // register() {
    //   auth
    //     .createUserWithEmailAndPassword(this.email, this.password)
    //     .then((user) => {
    //       db.collection("profiles")
    //         .doc(user.user.uid)
    //         .set({
    //           firstName: this.firstName,
    //           lastName: this.lastName,
    //         })
    //         .then(() => {

    //         })
    //         .catch(() => {

    //         });
    //     })
    //     .catch(() => {
    //       // const errorCode = error.code;
    //       // const errorMessage = error.message;
    //       // // ..

    //     });
    // },
  },
  components: {
    Breadcrumbs,
    Newsletter,
  },
};
</script>
